import React from "react";
import Headercrm from "../components/Headercrm";

function Crm() {
  return (
    <div style={{ backgroundColor: "#ebefff", minWidth: "100%" }}>
      <Headercrm />
    </div>
  );
}

export default Crm;
